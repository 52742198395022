import {
  AppstoreOutlined,
  BarChartOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  HomeOutlined,
  MacCommandOutlined,
  PlusCircleOutlined,
  ShopOutlined,
  TagsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
  WechatOutlined,
  StarOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ROLE_PERMISSIONS, SCREEN_SIZE } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'

const { Sider } = Layout

const NavigationDrawer = () => {
  const menuRef = useRef(null)
  const { user } = useAuth()
  const [collapsed, setCollapsed] = useState(false)

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed)
  }

  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []

  useEffect(() => {
    const listener = () => {
      if (window.innerWidth < SCREEN_SIZE.TABLET) {
        setCollapsed(true)
      }
    }
    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [])

  function handleBlur(e) {
    if (
      menuRef.current === e.relatedTarget ||
      menuRef.current.contains(e.relatedTarget)
    )
      return

    onCollapse(true)
  }

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <div
        onClick={() => onCollapse(!collapsed)}
        style={{
          width: '100%',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: collapsed ? 'center' : 'flex-end',
          alignItems: 'center',
          padding: collapsed ? 'auto' : '24px 24px 0px 24px',
          color: '#1890FF',
          backgroundColor: '#fff',
          borderRight: '1px solid #f0f0f0',
        }}
      >
        {!collapsed && (
          <div
            className="side-logo"
            style={{ backgroundImage: 'url(/images/atelier_logo.png)' }}
          />
        )}
        {collapsed ? (
          <DoubleRightOutlined
            style={{
              paddingBottom: collapsed ? 12 : 0,
            }}
          />
        ) : (
          <DoubleLeftOutlined />
        )}
      </div>
      <div style={{ height: '100%' }} ref={menuRef} onBlur={handleBlur}>
        <Menu
          defaultSelectedKeys={['1']}
          mode="inline"
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            pointerEvents: collapsed ? 'none' : 'auto',
            height: '100%',
          }}
        >
          <div
            style={{
              height: 1,
              background: 'rgba(100, 100, 100, 0.25)',
              width: '90%',
              marginLeft: '4%',
              paddingLeft: collapsed ? 10 : 15,
              marginBottom: 7,
              marginTop: 2,
            }}
          >
            <span
              style={{
                background: '#fff',
                padding: '0px 4px',
                color: 'rgba(100, 100, 100, 0.5)',
              }}
            >
              B2C
            </span>
          </div>
          <Menu.Item key="home" icon={<StarOutlined />}>
            <Link to="/">Main Items</Link>
          </Menu.Item>

          <Menu.Item key="orders" icon={<UnorderedListOutlined />}>
            <Link to="/orders">Client Follow-up</Link>
          </Menu.Item>

          {permissions.includes('new_orders') && (
            <Menu.Item key="new_orders" icon={<PlusCircleOutlined />}>
              <Link to="/orders/create">Create New Order</Link>
            </Menu.Item>
          )}

          {permissions.includes('clients') && (
            <Menu.Item key="clients" icon={<TeamOutlined />}>
              <Link to="/clients">Clients</Link>
            </Menu.Item>
          )}

          {permissions.includes('new_clients') && (
            <Menu.Item key="new_clients" icon={<UserAddOutlined />}>
              <Link to="/clients/create">Create New Client</Link>
            </Menu.Item>
          )}
          {(permissions.includes('products') ||
            permissions.includes('categories') ||
            permissions.includes('types') ||
            permissions.includes('product-tags') ||
            permissions.includes('new_adjustments')) && (
            <div
              style={{
                height: 1,
                background: 'rgba(100, 100, 100, 0.25)',
                width: '90%',
                marginLeft: '4%',
                paddingLeft: collapsed ? 10 : 15,
                marginBottom: 7,
                marginTop: 2,
              }}
            >
              <span
                style={{
                  background: '#fff',
                  padding: '0px 4px',
                  color: 'rgba(100, 100, 100, 0.5)',
                }}
              >
                PRODUCTS
              </span>
            </div>
          )}
          {permissions.includes('products') && (
            <Menu.Item key="products" icon={<UnorderedListOutlined />}>
              <Link to="/products">Product List</Link>
            </Menu.Item>
          )}

          {permissions.includes('categories') && (
            <Menu.Item key="categories" icon={<AppstoreOutlined />}>
              <Link to="/categories">Collections</Link>
            </Menu.Item>
          )}

          {permissions.includes('types') && (
            <Menu.Item key="types" icon={<MacCommandOutlined />}>
              <Link to="/product-types">Product Families</Link>
            </Menu.Item>
          )}

          {permissions.includes('product-tags') && (
            <Menu.Item key="product-tags" icon={<TagsOutlined />}>
              <Link to="/product-tags">Product Tags</Link>
            </Menu.Item>
          )}

          {permissions.includes('new_adjustments') && (
            <Menu.Item key="new_adjustment" icon={<PlusCircleOutlined />}>
              <Link to="/adjustments/create">New Adjustment</Link>
            </Menu.Item>
          )}

          {(permissions.includes('stores') ||
            permissions.includes('members') ||
            permissions.includes('mp') ||
            permissions.includes('metrics')) && (
            <div
              style={{
                height: 1,
                background: 'rgba(100, 100, 100, 0.25)',
                width: '90%',
                marginLeft: '4%',
                paddingLeft: collapsed ? 10 : 15,
                marginBottom: 7,
                marginTop: 2,
              }}
            >
              <span
                style={{
                  background: '#fff',
                  padding: '0px 4px',
                  color: 'rgba(100, 100, 100, 0.5)',
                }}
              >
                COMPANY
              </span>
            </div>
          )}
          {permissions.includes('stores') && (
            <Menu.Item key="stores" icon={<ShopOutlined />}>
              <Link to="/stores">Stores</Link>
            </Menu.Item>
          )}

          {permissions.includes('members') && (
            <Menu.Item key="members" icon={<UserOutlined />}>
              <Link to="/members">Team Members</Link>
            </Menu.Item>
          )}

          {permissions.includes('metrics') && (
            <Menu.Item key="metrics" icon={<BarChartOutlined />}>
              <Link to="/metrics">Metrics</Link>
            </Menu.Item>
          )}

          {permissions.includes('mp') && (
            <Menu.Item key="mp" icon={<WechatOutlined />}>
              <Link to="/mp">Landing Sections</Link>
            </Menu.Item>
          )}

          {(permissions.includes('companies') ||
            permissions.includes('uniforms') ||
            permissions.includes('companies_orders')) && (
            <div
              style={{
                height: 1,
                background: 'rgba(100, 100, 100, 0.25)',
                width: '90%',
                marginLeft: '4%',
                paddingLeft: collapsed ? 10 : 15,
                marginBottom: 7,
                marginTop: 2,
              }}
            >
              <span
                style={{
                  background: '#fff',
                  padding: '0px 4px',
                  color: 'rgba(100, 100, 100, 0.5)',
                }}
              >
                B2B
              </span>
            </div>
          )}

          {permissions.includes('companies') && (
            <Menu.Item key="companies" icon={<BarChartOutlined />}>
              <Link to="/companies">Companies</Link>
            </Menu.Item>
          )}
          {permissions.includes('uniforms') && (
            <Menu.Item key="uniforms" icon={<BarChartOutlined />}>
              <Link to="/uniforms">Uniforms</Link>
            </Menu.Item>
          )}
          {permissions.includes('companies_orders') && (
            <Menu.Item key="companies_orders" icon={<BarChartOutlined />}>
              <Link to="/companies/orders">Companies Orders</Link>
            </Menu.Item>
          )}

          {/* <div style={{ flex: 1 }} />
        <Menu.Item onClick={signOut} key="logout" icon={<LogoutOutlined />}>
          <Tooltip title={timeBeforeExpire}>Logout</Tooltip>
        </Menu.Item> */}
        </Menu>
      </div>
    </Sider>
  )
}

export default NavigationDrawer
